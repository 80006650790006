
export const useLeadGenRecData = () => {
  const config = useRuntimeConfig()
  const transform = (response) => {
    const { sanitize } = useUtils();      
    return {
      id: response.data.id,
      title: sanitize(response.data.attributes.title),
      slug: sanitize(response.data.attributes.slug),
      steps: sanitize(response.data.attributes.steps),
      customHeader: sanitize(response.data.attributes.customHeader),
      tagsArray: sanitize(response.data.attributes.tagsArray),
      seo: sanitize(response.data.attributes.seo),
      submitBtnTitle: sanitize(response.data.attributes.submitBtnTitle)
    }
  }
  
  const getRecPageBySlug = async (slug) => {    
    const {findOneBySlug} = useStrapiAsyncData();
    const url = 'lead-gen-recs';
    const params = {
      slug: slug,
      entity: 'lead-gen-recs'
    }
    return findOneBySlug(url, params, transform);
  }

  const getRecShared = async () => {

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch('/api/lead-gen-shared-config', {
        baseURL: config.public.nuxtAPIUrl,
      })
    }

    return await useFetch('/api/lead-gen-shared-config')
    
  }
  
  return {
      getRecPageBySlug,
      getRecShared
  }
}